"use client"

import * as Sentry from "@sentry/nextjs"
import Error from "next/error"
import { useEffect } from "react"

export default function GlobalError({
  reset,
  error,
}: {
  error: Error & { digest?: string }
  reset: () => void
}) {
  useEffect(() => {
    Sentry.captureException(error)
  }, [error])

  return (
    <html>
      <body>
        <h2>Etwas ist schief gelaufen!</h2>
        <button onClick={() => reset()}>Versuchen Sie es erneut</button>
      </body>
    </html>
  )
}
